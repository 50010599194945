html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

@font-face {
  font-display: fallback;
  font-family: 'Champion Gothic Heavyweight';
  src: 
   /*  url("./fonts/ChampionGothic-Heavyweight.eot") ;
  src: 
    url("./fonts/ChampionGothic-Heavyweight.ttf") format("truetype") */
    url("./fonts/ChampionGothic-Heavyweight.woff") format("woff")
  /*   url("./fonts/ChampionGothic-Heavyweight.woff2") format("woff2") */
    ;
}
@font-face {
  font-display: fallback;
font-family: 'Champion Gothic Middleweight';  
src: 
    /* url("./fonts/ChampionGothic-Middleweight.eot");
  src: 
    url("./fonts/ChampionGothic-Middleweight.ttf") format("truetype") */
    url("./fonts/ChampionGothic-Middleweight.woff") format("woff")
    /* url("./fonts/ChampionGothic-Middleweight.woff2") format("woff2") */
    ;
}
@font-face {
  font-display: fallback;
  font-family: 'Champion Gothic Featherweight'; 
  src: 
      /* url("./fonts/ChampionGothic-Featherweight.eot"); 
    src: 
      url("./fonts/ChampionGothic-Featherweight.ttf") format("truetype") */
      url("./fonts/ChampionGothic-Featherweight.woff") format("woff")
      /* url("./fonts/ChampionGothic-Featherweight.woff2") format("woff2") */
      ;
  }

  /* Sendinblue Form */
  @font-face {
    font-display: block;
    font-family: Roboto;
    src: url(https://assets.sendinblue.com/font/Roboto/Latin/normal/normal/7529907e9eaf8ebb5220c5f9850e3811.woff2) format("woff2"), url(https://assets.sendinblue.com/font/Roboto/Latin/normal/normal/25c678feafdc175a70922a116c9be3e7.woff) format("woff")
  }

  @font-face {
    font-display: fallback;
    font-family: Roboto;
    font-weight: 600;
    src: url(https://assets.sendinblue.com/font/Roboto/Latin/medium/normal/6e9caeeafb1f3491be3e32744bc30440.woff2) format("woff2"), url(https://assets.sendinblue.com/font/Roboto/Latin/medium/normal/71501f0d8d5aa95960f6475d5487d4c2.woff) format("woff")
  }

  @font-face {
    font-display: fallback;
    font-family: Roboto;
    font-weight: 700;
    src: url(https://assets.sendinblue.com/font/Roboto/Latin/bold/normal/3ef7cf158f310cf752d5ad08cd0e7e60.woff2) format("woff2"), url(https://assets.sendinblue.com/font/Roboto/Latin/bold/normal/ece3a1d82f18b60bcce0211725c476aa.woff) format("woff")
  }

  #sib-container input:-ms-input-placeholder {
    text-align: left;
    font-family: "Helvetica", sans-serif;
    color: #c0ccda;
  }

  #sib-container input::placeholder {
    text-align: left;
    font-family: "Helvetica", sans-serif;
    color: #c0ccda;
  }

  #sib-container textarea::placeholder {
    text-align: left;
    font-family: "Helvetica", sans-serif;
    color: #c0ccda;
  }